<template>
  <div class="patent-service-info">
    <div class="fast-application">
      <div class="title">3秒判定你的专利能否成功</div>
      <div class="intelligent">
        <div class="intelligent-title">{{ patentList.patentTitle }}</div>
        <div class="application-Info">
          <a-select
            label-in-value
            :default-value="{ key: '发明专利申请' }"
            style="width: 220px"
            @change="handleChange"
          >
            <a-select-option
              v-for="(item, index) in patentList.list"
              :key="index + 'select'"
            >
              {{ item }}
            </a-select-option>
          </a-select>
          <div style="margin-left: 20px">
            <a-input
              v-model="patentList.title"
              style="width: 220px"
              placeholder="意向专利名称"
            />
          </div>
          <div style="margin-left: 20px">
            <a-input
              v-model="patentList.tel"
              style="width: 220px"
              placeholder="手机号码"
            />
          </div>
          <div class="obtain-code" style="margin-left: 20px">
            <a-input
              v-model="patentList.code"
              class="inp"
              style="width: 110px"
              placeholder="验证码"
            />
            <span class="text"
              ><span
                style="border-radius: 10px;
                  cursor: pointer;
                  width: 100px;
                  text-align: center;"
                class="msg__btn"
                @click="getSmsCode"
                :disabled="state.smsSendBtn"
                >{{
                  state.smsSendBtn ? state.time : "获取验证码"
                }}</span
              >
            </span>
          </div>
          <a-button @click="setNeeds()" style="margin-left: 20px" type="danger">
            提交需求
          </a-button>
        </div>
      </div>
      <div class="fast-info">
        <div class="fast-title-info">
          <div class="header">快速申请专利</div>
          <div class="text">专业权威 快速响应</div>
        </div>
        <PatentApplication
          :showtitle="false"
          :patentlist="$store.state.home.patentlist.serviceList"
          :type="'patentlist'"
        />
      </div>
    </div>
    <div class="patent-value-info">
      <div class="value-info">
        <div class="value-title">专利价值</div>
        <div class="value-tab">
          <div
            class="value-item"
            v-for="(item, index) in $store.state.home.patentlist.copyrightList"
            :key="index + 'value'"
          >
            <div class="left">
              <div class="header">
                <img :src="item.imgurl" alt="" />
                <div class="title">{{ item.title }}</div>
                <div class="text">
                  {{ item.text }}
                </div>
              </div>
            </div>
            <div class="footer">
              <div class="price">
                <span class="text">申请价格:</span>
                <span class="sign">￥</span>
                <span class="money">{{ item.money }}</span>
              </div>
              <div class="btn" @click="showModel(item)">立即咨询</div>
            </div>
          </div>
        </div>
      </div>
      <div class="law-info">
        <div class="law-title">专利法律服务</div>
        <CopyrightRegistration
          :copyright="$store.state.home.patentlist.lawList"
        />
      </div>
      <div class="other-info">
        <div class="other-title">专利其他服务</div>
        <div class="other-tab">
          <div
            class="other-item"
            v-for="(item, index) in $store.state.home.patentlist.otherService"
            :key="index + 'other'"
          >
            <div class="left">
              <div class="header">
                <img :src="item.imgurl" alt="" />
                <div class="title">{{ item.title }}</div>
                <div class="text">
                  {{ item.text }}
                </div>
              </div>
            </div>
            <div class="footer">
              <div class="price">
                <span class="text">申请价格:</span>
                <span class="sign">￥</span>
                <span class="money">{{ item.money }}</span>
              </div>
              <div class="btn" @click="showModel(item)">立即咨询</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="process-info">
      <div class="flow-chart">
        <div class="title">专利申请流程图</div>
        <div class="flow-chart-tab">
          <div
            class="flow-chart-item"
            v-for="(item, index) in flowList"
            :key="index + 'flow'"
          >
            <div class="logo">
              <img :src="item.imgUrl" alt="" />
            </div>
            <div class="text">{{ item.text }}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="application-benefits">
      <div class="benefits-title">
        <div class="title">申请专利的好处</div>
        <div class="text">
          我国采用申请优先原则，即不论谁先完成发明创造，专利权授予最先提出专利申请的人
        </div>
      </div>
      <div class="benefits-tab">
        <div
          class="benefits-item"
          v-for="(item, index) in benefitsList"
          :key="index + 'benefits'"
        >
          <div class="logo"><img :src="item.imgUrl" alt="" /></div>
          <div class="title">{{ item.title }}</div>
          <div class="text">
            {{ item.text }}
          </div>
        </div>
      </div>
    </div>
    <Model ref="model" />
    <Floating :consultant='$store.state.user.guwen.patentlist' />
    <Footer />
  </div>
</template>

<script>
import { needs, smsCode } from "../../api/index";
import Floating from "../../comon/Floating.vue";
import Footer from "../../comon/Footer.vue";
import PatentApplication from "../comon/PatentApplication.vue";
import CopyrightRegistration from "../comon/CopyrightRegistration.vue";
import Model from "../../comon/model.vue";
import cookie from "../../utils/cookie";
export default {
  name: "PatentService",

  components: { PatentApplication, CopyrightRegistration, Footer, Model,Floating },

  directives: {},

  data() {
    return {
      form: this.$form.createForm(this, { name: "horizontal_login" }),
      state: {
        time: 60,
        loginBtn: false,
        // login type: 0 email, 1 username, 2 telephone
        loginType: 0,
        selectindex: 0,
        smsSendBtn: false,
        read: false,
      },
      patentList: {
        patentTitle: "智能专利申请",
        title: "",
        list: ["发明专利申请", "实用新型专利申请", "外观专利申请"],
        category: 1,
        tel: "",
        type: "发明专利申请",
        code: "",
      },
      benefitsList: [
        {
          imgUrl: require("../../assets/patentservice/benefits/benefits-icon0.png"),
          title: "独占市场",
          text: "一种产品只要授予专利权，就等于在市场上具有了独占权",
        },
        {
          imgUrl: require("../../assets/patentservice/benefits/benefits-icon1.png"),
          title: "技术保护",
          text: "防止他人模仿本企业开发的新技术、新产品",
        },
        {
          imgUrl: require("../../assets/patentservice/benefits/benefits-icon2.png"),
          title: "无形资产",
          text: "纯技术一旦授予专利权就变成了工业产权，形成了无形资产，具有了价值，可作为商品出售或转让",
        },
        {
          imgUrl: require("../../assets/patentservice/benefits/benefits-icon3.png"),
          title: "荣誉价值",
          text: "企业荣誉、提升价值、评定职称、个人成就实现",
        },
        {
          imgUrl: require("../../assets/patentservice/benefits/benefits-icon4.png"),
          title: "政策奖励",
          text: "各地方政府均出台相应文件，对专利申请者进行奖励或补助",
        },
        {
          imgUrl: require("../../assets/patentservice/benefits/benefits-icon5.png"),
          title: "企业宣传",
          text: "在宣传时打上专利标志，消费者认为这种商品更具可靠性、信用性，提高企业的知名度",
        },
      ],
      flowList: [
        {
          text: "申请资料准备",
          imgUrl: require("../../assets/patentservice/application-document.png"),
        },
        {
          text: "递交申请文件",
          imgUrl: require("../../assets/patentservice/application-information.png"),
        },
        {
          text: "缴纳申请费用",
          imgUrl: require("../../assets/patentservice/application-fee.png"),
        },
        {
          text: "初步审核阶段",
          imgUrl: require("../../assets/patentservice/review-stage.png"),
        },
        {
          text: "缴纳授权费用",
          imgUrl: require("../../assets/patentservice/authorization-fee.png"),
        },
      ],
    };
  },

  mounted() {},

  methods: {
    showModel(item) {
      this.$refs.model.visible = true;
      this.$store.state.home.modalParms = item;
    },
    async getSmsCode(e) {
      if (this.state.smsSendBtn) return this.$message.error("请勿重复提交验证码");
      if (this.patentList.tel === "")
        return this.$message.error("请填写手机号");
      e.preventDefault();
      const {
        form: { validateFields },
        state,
      } = this;
      const params = {
        phone: this.patentList.tel,
        useTo: 6,
      };
      const res = await smsCode(params);
      validateFields(["subForm[phone]"], { force: true }, (err) => {
        if (!err) {
          state.smsSendBtn = true;
          const interval = window.setInterval(() => {
            if (state.time-- <= 0) {
              state.time = 60;
              state.smsSendBtn = false;
              window.clearInterval(interval);
            }
          }, 1000);
          const hide = this.$message.loading("验证码发送中..", 0);
          // tianli
          if (res.code === 200) {
            setTimeout(() => {
              this.$message.success({
                content: "发送成功，请在手机中查看!",
                duration: 2,
              });
            }, 800);
          } else {
            setTimeout(() => {
              this.$message.success({
                content: "发送失败，请重新发送!",
                duration: 2,
              });
            }, 0);
          }
          setTimeout(hide, 2500);
        }
      });
    },
    async setNeeds() {
      if (this.patentList.title === "")
        return this.$message.error("请填写意向专利名称");
      if (this.patentList.tel === "")
        return this.$message.error("请填写手机号");
      if (this.patentList.code === "")
        return this.$message.error("请填写验证码");
      const params = {
        ownerId: cookie.getCookie("user")
          ? JSON.parse(cookie.getCookie("user")).id
          : null,
        code: this.patentList.code,
        title: this.patentList.title,
        category: this.patentList.category,
        tel: this.patentList.tel,
        type: this.patentList.type,
      };
      const res = await needs(params);
      if (res.code === 200) {
        this.$message.success("提交需求成功！");
        this.content = "";
        this.contact = "";
        this.tel = "";
        this.code = "";
        this.title = "";
      } else {
        this.$message.error(res.msg);
      }
    },
    handleChange(value) {
      this.patentList.type = value.label;
      console.log(value); // { key: "lucy", label: "Lucy (101)" }
    },
  },
};
</script>

<style lang="less">
@import "../../less/patentservice.less";
</style>
